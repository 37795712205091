import { apiCall } from '../utils';
import { fileActions } from '.';
import { USER_API, USER_TYPE, ROUTER } from '../constants';

export const userActions = {
  login,
  logout,
  register,
  notification,
  loginWithToken,
  changePassword,
  resetByEmail,
  getAllUser,
  modifyRole,
};


function login(navigate, username, password) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.login();
    const { response, error } = await apiCall({ ...api, payload: { username, password } });

    if (!error && response.status === 200 && response.data.success) {
      localStorage.setItem('access_token', response.data.token);
      localStorage.setItem('refresh_token', response.data.refreshToken);
      localStorage.setItem('last_login', new Date());
      dispatch(success(response.data.data));
      navigate(ROUTER.TONG_QUAN);
      dispatch(notification({ message: 'Đăng nhập thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Sai tên đăng nhập hoặc mật khẩu', type: 'error' }));
    }
  };
  function loading() { return { type: USER_TYPE.LOGIN_LOADING } }
  function success(payload) { return { type: USER_TYPE.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.LOGIN_FAILURE, error } }
}

function loginWithToken(refreshToken){
  // Không có => logout
  const lastLogin = localStorage.getItem('last_login');
  if (!lastLogin) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return { type: 'NULL' };
  }

  // Quá hạn => logout
  var date = new Date(lastLogin);
  date.setDate(date.getDate() + 2);
  // date = new Date(date.getTime() + 60000 / 2);
  if (new Date() >= date) {
    localStorage.removeItem('last_login');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return { type: 'NULL' };
  }

  return async(dispatch) => {
    dispatch(loading());
  
    const api = USER_API.loginWithToken();
    const {response, error} = await apiCall({...api, payload: {refreshToken}});

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(response.data.data));
    } else {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      dispatch(failure(error));
    }
  };

  function loading() { return { type: USER_TYPE.LOGIN_LOADING } }
  function success(payload) { return { type: USER_TYPE.LOGIN_WITH_TOKEN, payload } }
  function failure(error) { return { type: USER_TYPE.LOGIN_FAILURE, error } }
}

function register(navigate, values, avatar) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.register();
    const { response, error } = await apiCall({ ...api, payload: { 
      username: values?.email,
      profile: {
        birth: values?.birth,
        email: values?.email,
        ethnic: values?.ethnic,
        gender: values?.gender,
        name: values?.name,
        phone: values?.phone,
        website: values?.website,
      },
      role: 'candidate',
    }});

    if (!error && response.status === 200 && response.data.success) {
      const profileId = response.data.data.profile.id;
      // const profile
      if (avatar && profileId) {
        dispatch(fileActions.importFile(profileId, avatar, response.data.data.profile));
      }
      dispatch(success(response.data.data));
      navigate(ROUTER.DANG_NHAP);
      dispatch(notification({ message: 'Đăng ký thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      if (response.data.message.includes('duplicate') && response.data.message.includes('username')) {
        dispatch(notification({ message: 'Email đã được sử dụng', type: 'error' }));
      } else {
        dispatch(notification({ message: 'Đăng ký thất bại', type: 'error' }));
      }
    }
  };

  function loading() { return { type: USER_TYPE.REGISTER_LOADING } }
  function success(payload) { return { type: USER_TYPE.REGISTER_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.REGISTER_FAILURE, error } }
}

function logout(navigate) {
  return (dispatch) => {
    setTimeout(() => dispatch(notification({ message: 'Đăng xuất thành công', type: 'success' })), 100);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    navigate(ROUTER.HOME);
    dispatch(logout());
  }
  function logout() { return { type: USER_TYPE.LOGOUT } }
}

function changePassword(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.changePassword();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success());
      dispatch(notification({ message: 'Đổi mật khẩu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Đổi mật khẩu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: USER_TYPE.CHANGE_PASSWORD_LOADING } }
  function success() { return { type: USER_TYPE.CHANGE_PASSWORD_SUCCESS } }
  function failure(error) { return { type: USER_TYPE.CHANGE_PASSWORD_FAILURE, error } }
}

function resetByEmail(email) {
  return async(dispatch) => {
    dispatch(loading());

    const top = email.split('@')?.[0];
    const bot = email.split('@')?.[1];

    const api = USER_API.resetByEmail(top, bot);
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success());
      return true;
    } else {
      dispatch(failure());
      return false;
    }
  };

  function loading() { return { type: USER_TYPE.RESET_BY_EMAIL_LOADING } }
  function success() { return { type: USER_TYPE.RESET_BY_EMAIL_SUCCESS } }
  function failure() { return { type: USER_TYPE.RESET_BY_EMAIL_FAILURE } }
}

function getAllUser() {
	return async (dispatch) => {
		dispatch(loading());

		const api = USER_API.getAllUser();
		const { response, error } = await apiCall({ ...api });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
			// dispatch(notification({ message: 'Lấy kinh nghiệm đào tạo thành công', type: 'success' }));
		} else {
			dispatch(failure(error));
			dispatch(notification({ message: 'Lấy danh sách tài khoản thất bại', type: 'error' }));
		}
	};

	function loading() { return { type: USER_TYPE.GET_ALL_USER_LOADING } }
	function success(payload) { return { type: USER_TYPE.GET_ALL_USER_SUCCESS, payload } }
	function failure(error) { return { type: USER_TYPE.GET_ALL_USER_FAILURE, error } }
}

function notification(payload) {
  return { type: USER_TYPE.NOTIFICATION, payload };
}

function modifyRole(payload) {
	return async (dispatch) => {
		dispatch(loading());

		const api = USER_API.modifyRole();
		const { response, error } = await apiCall({ ...api, payload });

		if (!error && response.status === 200 && response.data.success) {
			dispatch(success(response.data.data));
		} else {
			dispatch(failure(error));
		}
	}

	function loading() { return { type: USER_TYPE.MODIFY_ROLE_LOADING } }
	function success(payload) { return { type: USER_TYPE.MODIFY_ROLE_SUCCESS, payload } }
	function failure(error) { return { type: USER_TYPE.MODIFY_ROLE_FAILURE, error } }
}
