import React, { useEffect } from 'react';
import { Link, Chip } from '@material-ui/core';
import { useSelector, useDispatch} from 'react-redux';

import KPTable from '../../../../components/KPTComponents/KPTable';
import exportProfileQCV from '../../../../utils/qcv.exportProfile';
import exportProfileXHNV from '../../../../utils/xhnv.exportProfile';

import { formActions } from '../../../../actions';
import { APP_SCENE, CATEGORY } from '../../../../constants/enums';
import { ROUTER, FORM_CONSTANTS } from '../../../../constants';

const EXPORT_FUNC = {
  [CATEGORY.QCV]: exportProfileQCV,
  [CATEGORY.XHNV]: exportProfileXHNV,
}

export default function DanhSachHoSoTable(props) {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.formReducer.loading);
	const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);

	useEffect(()=>{
		if (openSeasonId) {
			dispatch(formActions.filterApplicationForm({
				seasonId: openSeasonId,
				category: APP_SCENE,
				status: ['unsubmit', 'in_review', 'need_update', 'approved']
				// status: ['in_review', 'need_update', 'approved']
			}));
		}
	}, [openSeasonId]);

	const renderStatus = (status) => {
		var label = 'Đang hoàn thiện', color = 'default', variant = 'outlined';
		if (status === 'in_review') {
			label = 'Đã nộp';
			color = 'primary';
		} else if (status === 'need_update') {
			label = 'Đã bị loại';
			color = 'secondary';
		} else if (status === 'approved') {
			label = 'Đã được duyệt';
			color = 'primary';
		}

		if (status === 'need_update' || status === 'approved') {
			return <Chip size='small' color={color} label={label} style={{ width: '100%' }} />
		}

		return <Chip size='small' variant={variant} color={color} label={label}style={{ width: '100%' }} />
	}

	const mobileMode = window.innerWidth <= 768;
	var columns = [
		{ title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
		{ title: <b>Tên ứng viên</b>, dataIndex: 'profile', width: '20%', render: (value) => value?.name },
		{
			title: <b>Lĩnh vực</b>,
			dataIndex: 'field',
			width: '30%',
			render: (value) => FORM_CONSTANTS[`FIELD_${APP_SCENE}_OPTIONS`].find((e) => e.value === value)?.title,
		},
		{
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      width: '20%',
      filters: [
        { text: 'Đang hoàn thiện', value: 'unsubmit' },
        { text: 'Đã nộp', value: 'in_review' },
        { text: 'Đã được duyệt', value: 'approved' },
      ],
      onFilter: (value, row) => row.status.includes(value),
      render: (value) => renderStatus(value),
    },
	{
      title: <b>Tác vụ</b>,
      dataIndex: 'actions',
      width: '25%',
      render: (_, row) => (
        <>
          <Link href={`${ROUTER.QCV.DUYET_THANH_TICH}/${row.id}`}>Duyệt thành tích</Link>
          {' | '}
          <Link href={`${ROUTER.HO_SO}/${row.id}`}>Xem hồ sơ</Link>
          {' | '}
          <Link style={{ cursor: "pointer" }} onClick={() => EXPORT_FUNC[APP_SCENE](row)} href={undefined}>Xuất hồ sơ</Link>
        </>
      )
    }
	];

	if (mobileMode) columns = [
    {
			...columns[1],
			width: '38%',
			render: (value, row, id) => (
				<>
					<b>{id + 1}. {value?.name}</b>
					<div>{`(Lĩnh vực: ${FORM_CONSTANTS[`FIELD_${APP_SCENE}_OPTIONS`].find((e) => e.value === row.field)?.title})`}</div>
				</>
			)
		},
    {...columns[3], width: '35%'},
    {...columns[4], width: '20%'},
  ];

	return <KPTable loading={loading} columns={columns} rows={props.rows} />
}
