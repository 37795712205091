import React from 'react';
import { Language, Mail, Phone, Room } from '@material-ui/icons';
import { Box, Container, Divider, Typography } from '@material-ui/core';

import { useScreenSize } from '../../../hooks/useScreenSize';

import styles from './index.module.css';
import footer from '../../../assets/image/app/footer.jpg';
import tnvLogo from '../../../assets/image/app/tnv-logo.png';
import fdaLogo from '../../../assets/image/app/FDA-logo.png';
import gmobileLogo from '../../../assets/image/app/G-mobile.png';

function AppFooter() {
  const isMobileMode = useScreenSize();

  const logoWidth = isMobileMode ? 70 : 90;
  console.log(isMobileMode);

  return (
    <Box className={styles.container} style={{ backgroundImage: `url(${footer})` }}>
      <Container maxWidth='lg'>
        <Box
          display="flex"
          // flexDirection={"column"}
          flexDirection={isMobileMode ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMobileMode ? "center" : "flex-end"}
          padding={1}
          style={{ gap: 32 }}
        >
          <Box>
            <Typography className={styles.bold} variant={isMobileMode ? 'h6' : 'h5'}>
              <b>ĐƠN VỊ THƯỜNG TRỰC GIẢI THƯỞNG</b>
            </Typography>
            <Box display="flex" marginTop={1}>
              <img src={tnvLogo} alt='logo' style={{ height: 64 }} />
              <Box marginLeft={1}>
                <Typography variant='h6'>
                  <b>Trung tâm Phát triển Khoa học, Công nghệ và Tài năng trẻ</b>
                </Typography>
                <Typography variant='subtitle1'>
                  Center for Young Talents, Science and Technology (CYTAST)
                </Typography>
              </Box>
            </Box>
            <Box marginTop={1}>
              <Box display='flex' alignItems='center' width='100%'>
                <Room style={{ fontSize: 16, marginRight: 8, color: '#ee6064' }} />
                <Typography variant='subtitle1'>Số 7 Xã Đàn, Đống Đa, Hà Nội</Typography>
              </Box>
              <Box display='flex' alignItems='center' width='100%'>
                <Phone style={{ fontSize: 16, marginRight: 8, color: '#ee6064' }} />
                <Typography variant='subtitle1'>024 35772351</Typography>
              </Box>
              <Box display='flex' alignItems='center' width='100%'>
                <Mail style={{ fontSize: 16, marginRight: 8, color: '#ee6064' }} />
                <Typography variant='subtitle1'>tainangviet.twd@gmail.com</Typography>
              </Box>
              <Box display='flex' alignItems='center' width='100%'>
                <Language style={{ fontSize: 16, marginRight: 8, color: '#ee6064' }} />
                <Typography variant='subtitle1'>http://tainangviet.vn</Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" marginBottom={1} style={{ gap: 42 }}>
              <img src={gmobileLogo} alt='gmobile-logo' style={{ height: logoWidth, objectFit: 'cover' }} />
              <Box bgcolor="#fff" borderRadius="50%" width={logoWidth} height={logoWidth}>
                <img className={styles.kiaiLogo} src={fdaLogo} alt='kiai-logo' style={{ width: logoWidth - 10, height: logoWidth - 10 }} />
              </Box>
            </Box>
            <Typography className={styles.bold} variant={isMobileMode ? 'h6' : 'h5'} style={{ textAlign: 'center' }}>
              <b>ĐƠN VỊ ĐỒNG HÀNH</b>
            </Typography>
          </Box>
        </Box>
      </Container>
      <Divider className={styles.divider} />
      <Typography variant='subtitle1' style={{ textAlign: 'center' }}>
        <span>Phát triển bởi&nbsp;</span>
        <a target='_blank' rel='noreferrer' style={{ color: '#ee6064', textDecoration: 'none' }}>
          <b>Trung tâm Nghiên cứu và chuyển giao công nghệ Trí tuệ nhân tạo và Khoa học dữ liệu (CADA), ĐHKTQD</b>
        </a>
      </Typography>
    </Box>
  )
}

export default AppFooter;
