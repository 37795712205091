import { HEADERS } from ".";

export const SOLUTIONS_API = {
  getAllSolutionsList: () => ({
    endPoint: "/api/v1/solution/list",
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  getSolutionByProfileId: (id) => ({
    endPoint: `/api/v1/solution/profile/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: "GET",
  }),
  insertSolution: () => ({
    endPoint: "/api/v1/solution/create",
    headers: HEADERS.TOKEN_HEADER,
    method: "POST",
  }),
  updateSolution: () => ({
    endPoint: "/api/v1/solution/update",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
  deleteSolution: (id) => ({
    endPoint: `/api/v1/solution/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: "DELETE",
  }),
  updateSolutionAccepted: () => ({
    endPoint: "/api/v1/formSolution/updateScore_Accepted",
    headers: HEADERS.TOKEN_HEADER,
    method: "PUT",
  }),
};

export const SOLUTIONS_TYPE = {
  GET_ALL_SOLUTION_LOADING: "GET_ALL_SOLUTION_LOADING",
  GET_ALL_SOLUTION_SUCCESS: "GET_ALL_SOLUTION_SUCCESS",
  GET_ALL_SOLUTION_FAILURE: "GET_ALL_SOLUTION_FAILURE",

  GET_SOLUTION_BY_PROFILE_ID_LOADING: "GET_SOLUTION_BY_PROFILE_ID_LOADING",
  GET_SOLUTION_BY_PROFILE_ID_SUCCESS: "GET_SOLUTION_BY_PROFILE_ID_SUCCESS",
  GET_SOLUTION_BY_PROFILE_ID_FAILURE: "GET_SOLUTION_BY_PROFILE_ID_FAILURE",

  INSERT_SOLUTION_LOADING: "INSERT_SOLUTION_LOADING",
  INSERT_SOLUTION_SUCCESS: "INSERT_SOLUTION_SUCCESS",
  INSERT_SOLUTION_FAILURE: "INSERT_SOLUTION_FAILURE",

  UPDATE_SOLUTION_LOADING: "UPDATE_SOLUTION_LOADING",
  UPDATE_SOLUTION_SUCCESS: "UPDATE_SOLUTION_SUCCESS",
  UPDATE_SOLUTION_FAILURE: "UPDATE_SOLUTION_FAILURE",

  DELETE_SOLUTION_LOADING: "DELETE_SOLUTION_LOADING",
  DELETE_SOLUTION_SUCCESS: "DELETE_SOLUTION_SUCCESS",
  DELETE_SOLUTION_FAILURE: "DELETE_SOLUTION_FAILURE",

  UPDATE_SOLUTION_ACCEPTED_LOADING: "UPDATE_SOLUTION_ACCEPTED_LOADING",
  UPDATE_SOLUTION_ACCEPTED_SUCCESS: "UPDATE_SOLUTION_ACCEPTED_SUCCESS",
  UPDATE_SOLUTION_ACCEPTED_FAILURE: "UPDATE_SOLUTION_ACCEPTED_FAILURE",
};

export const SOLUTIONS_CONSTANTS = {
  TYPE_OPTIONS: [
    { key: "sc", title: "Bằng sáng chế quốc tế", value: "sc" },
    { key: "scqg", title: "Bằng độc quyền sáng chế quốc gia", value: "scqg" },
    { key: "gp", title: "Giải pháp hữu ích", value: "gp" },
  ],
};
