import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Divider,
  Container,
} from "@material-ui/core";

import { ROUTER } from "../../../constants";
import { useScreenSize } from "../../../hooks/useScreenSize";

import tut from "../../../assets/image/home/tutorial-icon.png";
import QuyChe from "../QuyChe";
import backdrop from "../../../assets/image/home/backdrop.jpg";
import logoDoan from "../../../assets/image/home/logoDoan.png";
import paperIcon from "../../../assets/image/home/paper-icon.png";
import logoBoKHCN from "../../../assets/image/home/logoBoKHCN.png";
import bannerTitle from "../../../assets/image/home/ptns-banner-title.png";
import registerIcon from "../../../assets/image/home/register-icon.png";

const topicList = [
  {
    key: "thanh-tích",
    title: "Quy chế xét giải thưởng",
    containerColor: "#F5F1EE",
    bgcolor: "#eb564b",
    imgSource: paperIcon,
    to: "#quy-che",
    // to: 'https://qcv.tainangviet.vn/storage/awardRegister/profile/18/quy-che.pdf',
    description:
      "Giải thưởng Nữ sinh khoa học công nghệ Việt Nam ban hành kèm theo Quyết định số 153 -QĐ/TWWĐTN-KHCN ngày 1 tháng 6 năm 2023 của Ban Bí thư Trung ương Đoàn.",
  },
  {
    key: "dang-ky",
    title: "Đăng ký",
    containerColor: "#EFEEF5",
    bgcolor: "#3ca370",
    imgSource: registerIcon,
    to: ROUTER.DANG_KY_HO_SO,
    description:
      "Đăng ký tham gia hệ thống bằng việc hoàn thiện hồ sơ đăng ký và chờ hệ thống xét duyệt.",
  },
  {
    key: "binh-chọn",
    title: "Hướng dẫn đăng ký",
    containerColor: "#F4F5EE",
    bgcolor: "#ffe478",
    imgSource: tut,
    to: "https://qcv.tainangviet.vn/storage/awardRegister/profile/18/hdsd.pdf",
    description: "Vui lòng ấn vào Chi tiết bên dưới để xem hướng dẫn sử dụng!",
  },
];

function GioiThieu() {
  const isMobileMode = useScreenSize();

  return (
    <>
      <Box mb={2} style={{
        backgroundImage: `url(${backdrop})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <Container maxWidth="lg">
          <Box
            paddingY={6}
            display="flex"
            alignItems="center"
            flexDirection={isMobileMode ? "column" : "row"}
          >
            <Box display="flex">
              <img
                src={logoDoan}
                alt="logo-doan"
                style={{ minWidth: 64, width: "calc(100vw / 20)" }}
              />
              <img
                src={logoBoKHCN}
                alt="logo-bo-khcn"
                style={{
                  minWidth: 64,
                  width: "calc(100vw / 20)",
                  marginTop: 10,
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{ flexGrow: 1 }}
            >
              <img
                src={bannerTitle}
                alt="title"
                style={{
                  marginLeft: isMobileMode ? 0 : 8,
                  width: isMobileMode ? "100%" : "calc(100% - 100vw / 30)",
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignContent="center" justifyContent="center">
          {topicList.map((e) => (
            <Grid key={e.key} item xs={12} md={4}>
              <Card style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}>
                <Box
                  display="flex"
                  paddingY={4}
                  justifyContent="center"
                  alignItems="center"
                  bgcolor={e.containerColor}
                >
                  <CardMedia
                    src={e.imgSource}
                    component="img"
                    style={{ width: '45%' }}
                  />
                </Box>
                <CardContent style={{ flexGrow: 1, textAlign: "justify" }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: 24,
                      marginBottom: 8,
                      textAlign: "center",
                    }}
                  >
                    <b>{e.title}</b>
                  </Typography>
                  <Box style={{ color: "#55626a", lineHeight: "1.68421053em" }}>
                    {e.description}
                  </Box>
                </CardContent>
                <CardActions>
                  <a href={e.to} style={{ margin: "auto", color: "#ee6064" }}>
                    <b>Chi tiết</b>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Box id="quy-che" xs={12} lg={6} xl={5} style={{ paddingTop: "10px" }}>
          <Divider style={{
            width: 64,
            height: 4,
            backgroundColor: "#ee6064",
            margin: "64px auto 16px",
          }} />
          <Typography
            variant="h3"
            style={{ textAlign: "center", fontFamily: "WorkSans-ExtraBold" }}
          >
            <strong>QUY CHẾ</strong>
          </Typography>
          <Typography
            variant="h4"
            style={{ textAlign: "center", fontFamily: "WorkSans-ExtraBold" }}
          >
            <strong>GIẢI THƯỞNG NỮ SINH KHOA HỌC CÔNG NGHỆ VIỆT NAM</strong>
          </Typography>
          <Typography
            variant="h6"
            style={{ textAlign: "center", color: "#55626a" }}
          >
            <i>
              (Ban hành kèm theo Quyết định số 153 -QĐ/TWWĐTN-KHCN ngày 1
              tháng 6 năm 2023 của Ban Bí thư Trung ương Đoàn TNCS Hồ Chí
              Minh)
            </i>
          </Typography>
        </Box>
        <Box id="quy-che" xs={12} lg={10} xl={8}>
          <QuyChe />
        </Box>
        <Typography variant="h5" style={{ color: "black", textAlign: "end" }}>
          <strong>ĐƠN VỊ THƯỜNG TRỰC GIẢI THƯỞNG</strong>
        </Typography>
      </Container>
    </>
  );
}

export default GioiThieu;
