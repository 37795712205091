/* eslint-disable */
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  Button,
  Checkbox,
  IconButton,
  Typography,
  FormControlLabel,
  Divider,
  TextField,
  OutlinedInput, 
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core';
import { Add, Edit, Info, Visibility } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import InputActives from '../../ActivesField/InputActives';

import { ROUTER, FORM_CONSTANTS, ARCHIVEMENT_TYPE, KEYS } from '../../../../constants';
import { fileActions, userActions, formActions } from '../../../../actions';

import styles from './DangKyHoSoUpdate.module.css';

import theme from '../../../../theme';
import noImage from '../../../../assets/image/app/no-image.png';
import Loading from '../../../../components/CustomComponents/Loading';

import KPTable from '../../../../components/KPTComponents/KPTable';
import KPTextform from '../../../../components/KPTComponents/KPTextform';
import KPTextfield from '../../../../components/KPTComponents/KPTextfield';
import PreviewModal from '../../../../components/CustomComponents/PreviewModal';
// import ThanhTichInsert from '../../ThanhTichInsert/ThanhTichInsert';
import { columnRender } from '../../../../utils/nstb/columnRender';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import ThanhTichAlert from '../../../AppLayout/ThanhTichAlert/ThanhTichAlert';
import ThanhTichInsert from '../../../XHNV/ThanhTichInsert/ThanhTichInsert';
import { jumpToReleventDiv } from '../../../../components/KPTComponents/utils';

const Levels = [
  { key: 'national', title: 'Quốc gia', value: 'national' },
  { key: 'partyLocalHierarchy', title: 'Cấp bộ', value: 'partyLocalHierarchy' },
  { key: 'province', title: 'Tỉnh, thành phố', value: 'province' },
  { key: 'stateAgencies', title: 'Cơ quan, đơn vị', value: 'stateAgencies' },
];

export default function DangKiHoSo() {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const { fieldNSTBOptions, politicalUnionOptions } = FORM_CONSTANTS;

  const userId = useSelector((state) => state.userReducer.user?.id);
  const profile = useSelector((state) => state.profileReducer.profile);
  const seasonList = useSelector(state => state.seasonReducer.seasonList);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const applicationList = useSelector((state) => state.formReducer.applicationList);
  const application = applicationList?.length > 0 ? applicationList[0] : null;

  // ------------------ LOADING ------------------ //
  const loadingUser = useSelector((state) => state.userReducer.loading);
  const loadingForm = useSelector((state) => state.formReducer.loading);
  const loadingProfile = useSelector((state) => state.profileReducer.loading);
  const loading = loadingUser || loadingForm || loadingProfile;

  const rewards = useSelector((state) => 
    state.rewardReducer.personalRewardList?.map((e) => ({
      ...e, decisionDay: dayjs(e?.decisionDay).format('DD/MM/YYYY')
    }))
  );

  // ---------------- ARCHIVEMENTS ---------------- //
  const achievementData = useSelector((state) => ({
    [ARCHIVEMENT_TYPE.SOLUTION]: state.solutionReducer.personalSolutionList,

    [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: state.reportsReducer.personalReportList,

    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.DOMESTIC),
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.INTERNATIONAL),

    [ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT]: rewards.filter((e) => ['g1', 'g2', 'g3-1'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG]: rewards.filter((e) => ['g3-2', 'g4', 'g5'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS]: rewards.filter((e) => ['nstb-g1', 'nstb-g2', 'nstb-g3'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB]: rewards.filter((e) => ['nstb-n1', 'nstb-n2', 'nstb-n3', 'nstb-n4'].find((type) => type === e.type)),
    [ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT]: rewards.filter((e) => ['nstb-n5', 'nstb-n6', 'nstb-n7'].find((type) => type === e.type)),
  }));

  // ----------------- ACTIVITIES ----------------- //
  const [reward, setReward] = useState([]);
  const [socialActivity, setSocialActivity] = useState([]);
  const [majorAchievement, setMajorAchievement] = useState([]);

  const [file, setFile] = useState(null);
  const [values, setValues] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);

  const [uploadFiles, setUploadFiles] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [emptyKey, setEmptyKey] = useState(null);
  const [acceptRules, setAcceptRules] = useState(false);

  const handleFileChange = (e, name) => setFile({ ...file, [name]: e });

  const renderUploadFile = (title, value) => {
    const url = values?.[`upload${value[0].toUpperCase() + value.slice(1)}`];
    return (
      <>
        <Typography className='kpt-textfield-label'>
          {title}
          <span style={{ color: theme.palette.secondary.main }}>*</span>:
        </Typography>
        {url ? (
          <OutlinedInput
            id={file}
            fullWidth
            size='small'
            value={url.slice(url.indexOf('.') + 1)}
            variant='outlined'
            style={{ paddingRight: '0', backgroundColor: 'white', height: '35px' }}
            endAdornment={
              <Box
                display='flex'
                justifyContent='space-around'
                alignItems='center'
                // style={{ padding: '0 12px' }}
              >
                <Divider orientation='vertical' style={{ height: 35 }} />
                <IconButton
                  edge='center'
                  color='secondary'
                  title='Chi tiết minh chứng'
                  onClick={() => setPreviewFile(url)}
                >
                  <Visibility />
                </IconButton>
                <Divider orientation='vertical' style={{ height: 35 }} />
                <IconButton
                  edge='center'
                  color='primary'
                  title='Chỉnh sửa minh chứng'
                  onClick={() => {
                    setValues({
                      ...values,
                      [`upload${value[0].toUpperCase() + value.slice(1)}`]: null
                    })
                  }}
                >
                  <Edit />
                </IconButton>
              </Box>
            }
          />
        ) : (
          <>
            <Card style={{ borderColor: '#b3b9c3', padding: '5.5px 8px' }}>
              <input
                type='file'
                style={{ width: '100%' }}
                onChange={(e) => {
                  if (e.target.files[0].size > 10485760) {
                    e.target.value = null
                    dispatch(userActions.notification({
                      message: 'Dung lượng vượt quá hơn 10MB',
                      type: 'error'
                    }));
                  } else {
                    handleFileChange(e.target.files[0], value);
                  }
                }}
              />
            </Card>
            {emptyKey === `upload${value[0].toUpperCase() + value.slice(1)}` && !file?.[value] && (
              <Typography style={{ fontSize: 12, color: '#f9625b' }}>Không được để trống</Typography>
            )}
          </>
        )}
      </>
    );
  }

  console.log(values);

  const elmForm = [
    { key: 'name', width: isMobileMode ? 12 : 8, label: 'Họ và tên', value: profile?.name, required: true, disabled: true },
    {
      key: 'birth',
      width: isMobileMode ? 12 : 4,
      label: 'Ngày sinh',
      value: dayjs(profile?.birth).format('DD/MM/YYYY'),
      type: 'date',
      disabled: true,
      required: true,
    },
    { key: 'ethnic', width: isMobileMode ? 12 : 4, label: 'Dân tộc', value: profile?.ethnic, disabled: true, required: true },
    { key: 'phone', width: isMobileMode ? 12 : 8, label: 'Số điện thoại', value: profile?.phone, required: true, disabled: true },
    { key: 'email', width: 12, label: 'Email', value: profile?.email, required: true, disabled: true },
    { key: 'website', width: 12, height: 1, label: 'Website/Facebook', required: true, value: profile?.website, disabled: true },
    { key: 'nominator', width: 12, height: 1, label: 'Đơn vị đề cử', required: true, value: values?.nominator },
    { key: 'nominatorPhone', width: isMobileMode ? 12 : 5, label: 'Điện thoại liên hệ', required: true, value: values?.nominatorPhone },
    {
      key: 'uploadNominator',
      width: isMobileMode ? 12 : 7,
      label: 'File công văn giới thiệu của đơn vị đề cử',
      render: renderUploadFile(
        isMobileMode
          ? 'File Công văn giới thiệu của đ.vị đề cử'
          : 'Upload file Công văn giới thiệu của đơn vị đề cử',
        'nominator'
      ),
      required: true,
      value: file?.nominator,
    },
    { key: 'videoUrl', width: 12, label: 'Link video giới thiệu bản thân', required: true },
    {
      key: 'politicalUnion',
      width: isMobileMode ? 6 : 3,
      label: 'Đoàn thể chính trị',
      required: true,
      options: politicalUnionOptions,
    },
    {
      key: 'bonus',
      width: isMobileMode ? 6 : 3,
      label: 'Điểm ưu tiên',
      required: true,
      options: [
        { key: 'khong', title: 'Không', value: 'khong' },
        { key: 'khuyet-tat', title: 'Là nữ sinh viên khuyết tật', value: 'khuyet-tat' },
      ],
    },
    { key: 'languague', width: isMobileMode ? 12 : 6, label: 'Ngoại ngữ', required: true },
    { key: 'gifted', width: 12, height: 2, label: 'Năng khiếu', required: true },
    { key: 'university', width: 12, label: 'Tên trường/học viện đang theo học', required: true },
    { key: 'faculty', width: 12, label: 'Tên Khoa/Viện', required: true },
    { key: 'facultyPhone', width: 12, label: 'Điện thoại liên hệ Đoàn trường/học viện', required: true },
    { key: 'studentYear', width: isMobileMode ? 12 : 4, label: 'Sinh viên năm thứ', type: 'int', required: true },
    { key: 'major', width: isMobileMode ? 12 : 8, label: 'Ngành học', value: values?.major, required: true },
    {
      key: 'fieldATCompleted',
      width: isMobileMode ? 12 : 7,
      label: 'Lĩnh vực đăng ký',
      value: values?.field,
      options: FORM_CONSTANTS.FIELD_PTNS_OPTIONS,
      required: true,
    },
    {
      key: 'majorCourseNumber',
      width: isMobileMode ? 12 : 5,
      // label: 'Số tín chỉ chuyên ngành đã học',
      label: (
        <span>
          Số tín chỉ chuyên ngành đã học
          &nbsp;
          <Tooltip title="Các tín chỉ thuộc kiến thức theo khối ngành, nhóm ngành và ngành theo quy định trong khung chương trình đào tạo">
            <Info style={{ cursor: "pointer", fontSize: 16, margin: '-3px 0' }} />
          </Tooltip>
        </span>
        // <Typography variant='subtitle1'>(Trong trường hợp không có DOI)</Typography>
      ),
      value: values?.majorCourseNumber,
      type: 'int',
      required: true,
    },
    { key: 'gpa', width: isMobileMode ? 12 : 5, label: 'Điểm TB chung học tập (theo thang điểm 4)', type: 'float', required: true },
    {
      key: 'uploadGpa',
      width: isMobileMode ? 12 : 3,
      label: 'File bảng điểm học tập',
      render: renderUploadFile('File Bảng điểm học tập', 'gpa'),
      value: file?.gpa,
      required: true,
    },
    {
      key: 'uploadCurriculum',
      width: isMobileMode ? 12 : 4,
      label: 'File khung chương trình đào tạo',
      render: renderUploadFile('File Khung chương trình đào tạo', 'curriculum'),
      value: file?.curriculum,
      required: true,
    },
    // {
    //   key: 'majorGpa',
    //   width: isMobileMode ? 12 : 7,
    //   label: 'Điểm TB các môn chuyên ngành (theo thang điểm 4)',
    //   type: 'float',
    //   value: values?.majorGpa,
    //   required: true,
    // },
    // {
    //   key: 'majorCourseNumber',
    //   width: isMobileMode ? 12 : 7,
    //   label: 'Số môn chuyên ngành',
    //   value: values?.majorCourseNumber,
    //   type: 'int',
    //   required: true,
    // },
    // {
    //   key: 'uploadMajorGpa',
    //   width: isMobileMode ? 12 : 5,
    //   render: renderUploadFile('Upload file Bảng điểm các môn chuyên ngành', 'majorGpa'),
    //   required: true,
    // },
  ];

  const topPart = isMobileMode ? elmForm.slice(0, 3) : elmForm.slice(0, 6);
  const botPart = isMobileMode ? elmForm.slice(3, 6) : [];
  const partI = elmForm.slice(6, 10);
  const partII = isMobileMode ? elmForm.slice(10, 14) : elmForm.slice(10, 14);
  const partIII = isMobileMode ? elmForm.slice(14) : elmForm.slice(14);

  const seasonYear = seasonList.filter(elm => elm.id === openSeasonId);
  const deadline = seasonYear[0]?.closeDate ? new Date(seasonYear[0].closeDate) : null;

  const actives = [
    {
      key: 'reward',
      values: reward,
      setValues: setReward,
      label: isMobileMode
        ? 'Thành tích khen thưởng'
        : 'Thành tích khen thưởng (Bằng khen, Giấy khen... xếp thứ tự từ khen thưởng cao xuống thấp)',
      fields: [
        { key: 'rewardContent', width: isMobileMode ? 12 : 4, label: 'Hình thức và nội dung thành tích', required: true },
        { key: 'rewardType', width: isMobileMode ? 12 : 3, label: 'Cấp thành tích', options: Levels, required: true },
        { key: 'rewardFile', width: isMobileMode ? 12 : 4, label: 'Minh chứng', type: 'file', required: true },
      ],
    },
    {
      key: 'socialActivity',
      values: socialActivity,
      setValues: setSocialActivity,
      label: 'Thành tích hoạt động Đoàn, Hội, Đội',
      fields: [
        { key: 'socialActivityContent', width: isMobileMode ? 12 : 7, label: 'Mô tả', required: true },
        { key: 'socialActivityFile', width: isMobileMode ? 12 : 4, label: 'Minh chứng', type: 'file', required: true },
      ],
    },
  ];

  const archivementList = [
    {
      index: '1',
      type: ARCHIVEMENT_TYPE.SOLUTION,
      title: 'Có bằng độc quyền sáng chế, giải pháp hữu ích thuộc các ngành xét giải',
      data: achievementData[ARCHIVEMENT_TYPE.SOLUTION]?.map((e) => ({ ...e, issueAt: dayjs(e?.issueAt).format('DD/MM/YYYY') })),
    },
    {
      index: '2',
      title: 'Có bài báo khoa học đăng trên tạp chí quốc tế/quốc gia thuộc các ngành xét giải',
      children: [
        {
          index: '2.1',
          type: ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL,
          title: 'Bài báo khoa học trên tạp chí uy tín quốc tế ',
          data: achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
        },
        {
          index: '2.2',
          type: ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC,
          title:
            'Bài báo khoa học đăng trên tạp chí trong nước (các tạp chí thuộc danh mục tính điểm của Hội đồng chức danh GSNN)',
          data: achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
          defaultData: { type: 'q6' }
        },
      ],
    },
    {
      index: '3',
      title: 'Có bài báo khoa học đăng trên hội thảo quốc tế/quốc gia thuộc các ngành xét giải',
      type: ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT,
      data: achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT],
    },
    {
      index: '4',
      title: 'Tham gia nghiên cứu khoa học thuộc các ngành xét giải',
      children: [
        {
          index: '4.1',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB,
          title: 'Đề tài nghiên cứu khoa học cấp Bộ đã nghiệm thu đạt',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB],
        },
        {
          index: '4.2',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT,
          title: 'Đề tài nghiên cứu khoa học cấp Trường đã nghiệm thu đạt',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT],
        },
      ],
    },
    {
      index: '5',
      title: 'Đạt Huy chương/Giải thưởng thuộc các ngành xét giải',
      children: [
        {
          index: '5.1',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT,
          title: 'Huy chương/Giải thưởng cấp quốc tế',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT],
        },
        {
          index: '5.2',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG,
          title: 'Huy chương/Giải thưởng cấp quốc gia',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG],
        },
        {
          index: '5.3',
          type: ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS,
          title: 'Huy chương/Giải thưởng cấp tỉnh, thành, ngành',
          data: achievementData[ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS],
        },
      ],
    },
  ];

  const handleChangeActivityForm = (name, event, type, values, setValues) => {
    const keyList = name.split(".");
    const key = keyList[2];
    const index = parseInt(keyList[1]);

    const tmp = [...values];
    if (type === "removeFile") {
      tmp.splice(index, 1, { ...tmp[index], [key]: undefined }); 
    } else {
      tmp.splice(index, 1, { ...tmp[index], [key]: event.target.value });
    }
    setValues(tmp);
  };

  const handleReload = (id) => dispatch(formActions.getApplicationFormv2(id));

  const handleSave = (status) => {
    for (const elm of elmForm) {
      if (status === "unsubmit") continue;

      const value = elm?.value || values?.[elm.key];
    
      if (!elm.disabled && elm.required && !value) {
        setValues({ ...values, emptyKey: elm.key });
        jumpToReleventDiv(elm.key);
        dispatch(
          userActions.notification({
            message: `Không được để trống thông tin về: ${elm?.renderLabel || elm?.label}`,
            type: 'error',
          }),
        );
        return;
      }
    }

    for (const active of actives) {
      if (status === "unsubmit") continue;

      for (const field of active.fields) {
        if (field.required) {
          let key = field.key;
          for (var i = 0; i < active.values.length; i++) {
            const value = active.values[i];
            if ((!value[key] || value[key] == '[]') && !file?.[`${active.key}.${i}.${key}`]) {
              setEmptyKey(key);
              dispatch(
                userActions.notification({
                  message: `Không được để trống thông tin về: ${field.label}`,
                  type: 'error',
                }),
              );
              return;
            }
          }
        }
      }
    }
    // if (status != 'unsubmit') {
    //   for (const elm of elmForm) {
    //     var value = elm?.value;
    //     if (elm?.key === 'uploadGpa' || elm?.key === 'uploadNominator') {
    //       value = value || values?.[elm?.key];
    //     }

    //     if (!elm?.disabled && elm?.required && !value) {
    //       setEmptyKey(elm.key);
    //       dispatch(
    //         userActions.notification({
    //           message: `Không được để trống thông tin về: ${elm?.renderLabel || elm?.label}`,
    //           type: 'error',
    //         }),
    //       );
    //       return;
    //     }
    //   }
    //   for (const active of actives) {
    //     for (const field of active.fields) {
    //       if (field.required) {
    //         let key = field.key;
    //         for (var i = 0; i < active.values.length; i++) {
    //           const value = active.values[i];
    //           if ((!value[key] || value[key] == '[]') && !file?.[`${active.key}.${i}.${key}`]) {
    //             setEmptyKey(key);
    //             dispatch(
    //               userActions.notification({
    //                 message: `Không được để trống thông tin về: ${field.label}`,
    //                 type: 'error',
    //               }),
    //             );
    //             return;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    setUploadFiles(true);

    dispatch(fileActions.importMultiFileQCV(profile?.id, file)).then((response) => {
      setUploadFiles(false);

      const gpa = response?.find((file) => file.includes('gpa')) || values?.uploadGpa;
      const nominator = response?.find((file) => file.includes('nominator')) || values?.uploadNominator;
      const curriculum = response?.find((file) => file.includes('curriculum')) || values?.uploadNominator;

      const payload = {
        ...values,
        seasonId: openSeasonId,
        profileId: profile?.id,
        category: 'nstb',
        status,
        reward: reward
          ? JSON.stringify(
              reward
                ?.filter((elm) => Object.values(elm).length !== 0)
                ?.map((e, index) => ({
                  ...e,
                  rewardFile:
                    response?.find((file) => {
                      const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];

                      const keyList = name.split('.');
                      return keyList[0] === 'reward' && parseInt(keyList[1]) === index;
                    }) ||
                    e?.rewardFile ||
                    '[]',
                })) || '',
            )
          : '',
        socialActivity: socialActivity
          ? JSON.stringify(
              socialActivity
                ?.filter((elm) => Object.values(elm).length !== 0)
                ?.map((e, index) => ({
                  ...e,
                  socialActivityFile:
                    response?.find((file) => {
                      const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];
                      const keyList = name.split('.');
                      return keyList[0] === 'socialActivity' && parseInt(keyList[1]) === index;
                    }) ||
                    e?.socialActivityFile ||
                    '[]',
                })) || '',
            )
          : '',
        majorAchievement: majorAchievement
          ? JSON.stringify(
              majorAchievement
                ?.filter((elm) => Object.values(elm).length !== 0)
                ?.map((e, index) => ({
                  ...e,
                  majorAchievementFile:
                    response?.find((file) => {
                      const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];

                      const keyList = name.split('.');
                      return keyList[0] === 'majorAchievement' && parseInt(keyList[1]) === index;
                    }) ||
                    e?.majorAchievementFile ||
                    '[]',
                })) || '',
            )
          : '',
        previewFile: [gpa, nominator, curriculum].join(','),
        formReward: rewards.map((e) => ({ rewardId: e.id })),
        formSolution: achievementData[ARCHIVEMENT_TYPE.SOLUTION].map((e) => ({ solutionId: e.id })),
        formScientificResearch: [
          ...achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
          ...achievementData[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
        ].map((e) => ({ scientificResearchId: e.id })),
        // formMonograph: monographList.map((e) => ({ monographId: e.id })),
        // formTechCompany: techCompanyList.map((e) => ({ techCompanyId: e.id })),
        // formScientificReport: achievementData[].map((e) => ({  scientificReportId: e.id })),
        // formResearchProject: researchProjectList.map((e) => ({ researchProjectId: e.id })),
        // formHrDevelopment: hrDevelopmentList.map((e) => ({ hrDevelopmentId: e.id })),
      };
      // Hồ sơ rỗng thì tạo hồ sơ mới, không thì cập nhật đè lên hồ sơ cũ
      if (!application) {
        dispatch(formActions.insertApplicationForm(payload)).then(() => {
          // if (status === 'in_review')
          // window.location.reload();
        });
      } else {
        dispatch(formActions.updateApplicationForm(payload)).then(() => {
          // if (status === 'in_review') window.location.reload();
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave('in_review');
  };

  // Vừa vào trang thì lấy thông tin hồ sơ luôn
  useEffect(() => {
    if (!isNaN(profile?.id) && !isNaN(openSeasonId) && !application)
      dispatch(
        formActions.filterApplicationForm({
          category: 'nstb',
          seasonId: openSeasonId,
          profileId: profile?.id,
        }),
      ).then((payload) => {
        const application = payload?.[0];
        if (application) {
          const fileList = application?.previewFile.split(',');
          setValues({
            ...values,
            ...application,
            uploadGpa: fileList.find((e) => e.includes('gpa')),
            uploadNominator: fileList.find((e) => e.includes('nominator')),
            uploadCurriculum: fileList.find((e) => e.includes('curriculum')),
          });
          setReward(JSON.parse(application?.reward));
          setSocialActivity(JSON.parse(application?.socialActivity));
          setMajorAchievement(JSON.parse(application?.majorAchievement));
        }
      });
  }, [openSeasonId, profile?.id]);

  useEffect(() => {
    if (profile?.id) {
      handleReload(profile?.id);
    }
  }, [profile]);

  // if (!values) return null;

  return (
    <>
      <Grid className='grid-container' container justifyContent='center' style={{ padding: '32px 8px' }}>
        <Grid item xs={12} lg={10}>
          <Card style={{ width: '100%' }}>
            <Box height={6} bgcolor='#233871' />
            <Box padding={2}>
              <Typography variant='h4' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                <b>Đăng ký hồ sơ</b>
              </Typography>
            </Box>
          </Card>
          <form className='dkhs-container' id='form-update' defaultValue={values} onSubmit={handleSubmit}>
            <Card style={{ width: 'calc(100% - 32px)', padding: 16 }}>
              <Box display='flex'>
                <Box flexGrow={1} marginTop='4px'>
                  <KPTextform component='div' textForm={topPart} />
                </Box>
                <Box marginTop='4px' marginLeft={1}>
                  <Typography className='kpt-textfield-label'>
                    {isMobileMode ? 'Ảnh chân dung' : 'Ảnh chân dung (4x6)'}
                    <span style={{ color: theme.palette.secondary.main }}>*</span>
                    {':'}
                  </Typography>
                  <Box>
                    <Card className={styles['dkhs-photoContainer']}>
                      <img
                        alt='anh-chan-dung'
                        className={styles['dkhs-photo']}
                        src={profile?.photoUrl ? `${process.env.REACT_APP_DOMAIN}${profile?.photoUrl}` : noImage}
                      />
                    </Card>
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1} marginTop='4px'>
                <KPTextform component='div' textForm={botPart} />
              </Box>
            </Card>
            <Card style={{ width: 'calc(100% - 32px)', padding: 16, marginTop: 32 }}>
              <KPTextform component='div' textForm={partI} values={values} setValues={setValues} />
            </Card>
            <Card style={{ width: 'calc(100% - 32px)', padding: 16, marginTop: 32 }}>
              <KPTextform component='div' textForm={partII} values={values} setValues={setValues} />
            </Card>
            <Card style={{ width: 'calc(100% - 32px)', padding: 16, marginTop: 32 }}>
              <Box component='form'>
                <Grid container spacing={1} justifyContent='center'>
                  {partIII.map((elm, idx) => (
                    <Grid item xs={elm.width} key={elm.key}>
                      {elm.key == 'fieldATCompleted' ? (
                        <>
                          <Typography className='kpt-textfield-label'>
                            {elm.label}
                            <span style={{ color: theme.palette.secondary.main }}>*</span>:
                          </Typography>
                          {elm.options && (
                            <Autocomplete
                              fullWidth
                              size='small'
                              disableClearable
                              options={elm.options}
                              getOptionLabel={(option) => option.title || ''}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  variant='outlined'
                                  inputProps={{ ...params.inputProps, style: { ...params.inputProps.style, padding: 2 } }}
                                />
                              )}
                              // value={elm.options.find((option) => option.key === elm.value)}
                              value={elm.options.find((option) => option.key === elm.value)}
                              // value={elm.options.find((option) => option.key === elm?.value) || elm.options[0]}
                              onChange={(_, value) => setValues({ ...values, field: value.value})}
                              required={elm.required}
                            />
                          )}
                        </>
                      ) : (
                        <KPTextfield
                          {...elm}
                          id={elm.key}
                          value={!values?.[elm.key] && values?.[elm.key] !== 0 ? '' : values?.[elm.key]}
                          setValues={setValues}
                          error={!elm?.disabled && elm.key === emptyKey}
                          handleChangeForm={(id, e) => {
                            if (elm.type === 'int') {
                              setValues({ ...values, [id]: parseInt(e.target.value) });
                            } else if (elm.type === 'float') {
                              setValues({ ...values, [id]: parseFloat(e.target.value) });
                            } else if (elm.type === 'file') {
                              setValues({ ...values, [id]: e });
                            } else {
                              setValues({ ...values, [id]: e.target.value });
                            }
                          }}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>
            <Card style={{ width: '100%', marginTop: 96 }}>
              <Box height={6} bgcolor='#233871' />
              <Box padding={2}>
                <Typography variant='h5' style={{ textAlign: 'center' }}>
                  <b>THÀNH TÍCH KHOA HỌC CÔNG NGHỆ</b>
                </Typography>
              </Box>
            </Card>

            {archivementList.map((e, id) => (
              <Card key={e?.index} style={{ width: '100%', marginTop: 32 }}>
                <Box padding={e.type ? '2px 4px 2px 16px' : '8px 16px'} display='flex' alignItems='center'>
                  <Typography variant='h6' style={{ flexGrow: 1 }}>
                    <b>{`${e?.index}. ${e?.title}`}</b>
                  </Typography>
                  {e.type && (
                    <>
                      <Tooltip arrow title={<ThanhTichAlert closable={false} />}>
                        <Info style={{ cursor: "pointer" }} />
                      </Tooltip>
                      <IconButton
                        color='primary'
                        onClick={() => setDialog({ type: e?.type, title: e?.title })}
                      >
                        <Add />
                      </IconButton>
                    </>
                  )}
                </Box>
                {e.type ? (
                  <>
                    <Divider />
                    <KPTable nonBorder={true} columns={columnRender(e, setDialog, setPreviewFile)} rows={e.data || []} />
                  </>
                ) : (
                  <>
                    {e.children.map((item) => (
                      <>
                        <Divider />
                        <Box padding='2px 4px 2px 16px' display='flex' alignItems='center'>
                          <Typography variant='h6' style={{ flexGrow: 1 }}>
                            <b>{`${item?.index}. ${item?.title}`}</b>
                          </Typography>
                          <Tooltip arrow title={<ThanhTichAlert closable={false} />}>
                            <Info style={{ cursor: "pointer" }} />
                          </Tooltip>
                          <IconButton
                            color='primary'
                            onClick={() =>
                              setDialog({
                                type: item?.type,
                                title: item?.title,
                                data: item?.defaultData,
                              })
                            }
                          >
                            <Add />
                          </IconButton>
                        </Box>
                        <Divider />
                        <KPTable nonBorder={true} columns={columnRender(item, setDialog, setPreviewFile)} rows={item.data || []} />
                      </>
                    ))}
                  </>
                )}
              </Card>
            ))}

            <Card style={{ width: '100%', marginTop: 96 }}>
              <Box height={6} bgcolor='#233871' />
              <Box padding={2}>
                <Typography variant='h5' style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                  <b>Thành tích khen thưởng và hoạt động Đoàn, Hội, Đội</b>
                </Typography>
              </Box>
            </Card>
            {actives.map((active, index) => (
              <Card key={active.key} style={{ width: '100%', marginTop: 32 }}>
                <InputActives
                  id={active.key}
                  {...active}
                  // newFileLength={newFileLength[active.key]}
                  setPreview={setPreviewFile}
                  handleFileChange={handleFileChange}
                  handleChangeForm={handleChangeActivityForm}
                />
              </Card>
            ))}

            <Card style={{ width: 'calc(100% - 32px)', padding: 16, marginTop: 32 }}>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>
                    <i>
                      <b>Lưu ý: </b>Công văn giới thiệu, bảng điểm, bằng khen, giấy khen, giấy chứng nhận hay các văn
                      bản có liên quan làm minh chứng phải được scan bản gốc hoặc có chữ ký, đóng dấu đỏ của đơn vị chủ
                      quản.
                    </i>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox color='secondary' checked={acceptRules} onChange={() => setAcceptRules(!acceptRules)} />
                    }
                    label={
                      <Typography variant='subtitle1'>
                        <b style={{ fontFamily: 'SFProDisplay' }}>
                          Tôi xin hoàn toàn chịu trách nhiệm trước pháp luật, Ban tổ chức Giải thưởng về tính chính xác
                          và các thông tin đã khai trong hồ sơ đăng ký trực tuyến tham gia xét giải thưởng
                        </b>
                      </Typography>
                    }
                    style={{ marginTop: 48 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth disabled={!acceptRules} variant='outlined' onClick={() => handleSave('unsubmit')}>
                    Lưu bản nháp
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    disabled={!acceptRules}
                    form='form-update'
                    type='submit'
                    color='secondary'
                    variant='contained'
                  >
                    Nộp hồ sơ
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </form>
        </Grid>
      </Grid>

      <ThanhTichInsert dialog={dialog} setDialog={setDialog} />

      {/* <Loading open={loading || updatingForm || uploadFiles} /> */}
      <PreviewModal open={!!previewFile} url={previewFile} handleClose={() => setPreviewFile(null)} />

      <Dialog fullWidth maxWidth='xs' open={deadline && new Date() >= deadline}>
        <DialogContent>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            Đã hết thời hạn đăng ký
            <br />
            <b>Giải thưởng Nữ sinh Khoa học công nghệ <br /> năm 2023</b>
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button
            fullWidth
            href={ROUTER.QCV.HOME}
            variant='contained'
            color='secondary'
          >
            Về trang chủ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
