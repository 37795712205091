import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


import { useScreenSize } from '../../../hooks/useScreenSize';
import { USER_CONSTANTS } from '../../../constants';
import { userActions, formActions } from '../../../actions';

import KPTable from '../../../components/KPTComponents/KPTable';
import PopupMenu from '../../../components/CustomComponents/PopupMenu';
import KPTextform from '../../../components/KPTComponents/KPTextform';

function QuanLyNguoiDung(props) {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

	const role = useSelector(state => state.userReducer.user?.role);
  const loading = useSelector((state) => state.userReducer.loadingUser);
  const allUserList = useSelector((state) => state.userReducer.userList);
  const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);

  const isSuperAdmin = role === 'super_admin' || role?.includes('qcv_admin_');

  const [modifyMenuAnchor, setModifyMenuAnchor] = useState(false);
  const [values, setValues] = useState(null);

  const handleChangeForm = (name, event) => setValues({ ...values, [name]: event.target.value });

  const handleReload = () => {
    dispatch(userActions.getAllUser());
  };

  useEffect(() => {
    if (openSeasonId) dispatch(formActions.thongKe(openSeasonId));
  }, [openSeasonId]);

  useEffect(() => {
    if (!allUserList) handleReload();
  }, []);

  var newAllUserList = allUserList?.filter((e) => e.profile?.email && e.profile?.name);
  newAllUserList = newAllUserList?.map((e) => ({
    ...e,
    name: e.profile?.name,
    email: e.profile?.email,
  }));

  const openModifyMenu = (e, row) => {
    setValues({
      ...values,
      userId: row.id,
      role: row.role,
    });
    setModifyMenuAnchor(e.currentTarget);
  };
  const closeModifyMenu = () => {
    setValues(null);
    setModifyMenuAnchor(null);
  };

  const handleChangeRole = () => {
    dispatch(userActions.modifyRole(values));
    closeModifyMenu();
  };

  var columns = [
    {
      title: <b>STT</b>,
      dataIndex: 'id',
      width: '5%',
      render: (_, row, id) => id + 1,
    },
    {
      title: <b>Người dùng</b>,
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: <b>Email</b>,
      dataIndex: 'email',
      width: '30%',
      render: (value) => (value?.length > 14 && isMobileMode ? `${value.slice(0, 13)}...` : value),
    },
    {
      title: <b>Chức vụ</b>,
      dataIndex: 'role',
      width: '20%',
      render: (value) => USER_CONSTANTS.roleOptions.find((e) => value === e.value)?.title,
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'cc-actions',
      width: '15%',
      render: (_, row) => (
        <Typography
          variant='body2'
          color='primary'
          className='actions-button'
          onClick={(e) => {
            openModifyMenu(e, row);
            // setValues({ ...values, newRole: row?.role });
          }}
        >
          Cấp Quyền
        </Typography>
      ),
    },
  ];

  console.log(values);

  if (!isSuperAdmin) return null;

  return (
    <>
      <Grid container spacing={2} style={{ margin: '32px 0' }}>
        <Grid item xs={12} style={{ float: 'right' }}>
          <KPTable loading={loading} columns={columns} rows={newAllUserList} />
        </Grid>

        <PopupMenu
          position='center'
          anchorEl={modifyMenuAnchor}
          handleClose={closeModifyMenu}
          render={
            <div style={{ padding: 8, width: 320, minWidth: 320, maxWidth: 320 }}>
              <Typography style={{ paddingBottom: 16 }}>Cấp quyền cho người dùng này?</Typography>
              <KPTextform
                {...props}
                textForm={[{ key: 'newRole', width: 12, value: values?.newRole, options: USER_CONSTANTS.roleOptions, }]}
                handleChangeForm={handleChangeForm}
              />
              <Button
                variant='outlined'
                color='secondary'
                style={{ width: 'calc(50% - 4px)', marginTop: '10px' }}
                onClick={closeModifyMenu}
              >
                Huỷ
              </Button>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginLeft: 8,
                  width: 'calc(50% - 4px)',
                  marginTop: '10px',
                }}
                onClick={handleChangeRole}
              >
                Xác nhận
              </Button>
            </div>
          }
        />
      </Grid>

      {/* <Dialog fullWidth maxWidth='sm' open={thongKeOpen}>
        <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h5'>
              <b>Thống kê</b>
            </Typography>
            <IconButton onClick={() => setThongKeOpen(false)}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {thongKeLoading ? (
            <CircularProgress />
          ) : (
            <>
              <b>Số tài khoản trên hệ thống: {allUserList?.length}</b>
              <br />
              <br />
              <Divider />
              <br />
              <b>
                Số tài khoản đã nộp hồ sơ trên hệ thống QCV:{' '}
                {thongKeInfo?.registeredQCV || thongKeInfo?.registered || 'Đang cập nhập...'}
              </b>
              <br />
              <b>
                Số tài khoản có hồ sơ QCV đang trong quá trình hoàn thiện:{' '}
                {thongKeInfo?.processingCandidatesQCV || thongKeInfo?.processingCandidates || 'Đang cập nhập...'}
              </b>
              <br />
              <br />
              <Divider />
              <br />
              <b>Số tài khoản đã nộp hồ sơ trên hệ thống PTNS: {thongKeInfo?.registeredPTNS || 'Đang cập nhập...'}</b>
              <br />
              <b>
                Số tài khoản có hồ sơ PTNS đang trong quá trình hoàn thiện:{' '}
                {thongKeInfo?.processingCandidatesPTNS || 'Đang cập nhập...'}
              </b>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button color='primary' variant='contained' style={{ float: 'right' }} onClick={() => setThongKeOpen(false)}>
            Đóng
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
export default QuanLyNguoiDung;
